import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, withCtx as _withCtx, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-2a1993ac"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "wizard-container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_main_sidebar = _resolveComponent("main-sidebar")!
  const _component_header_wizard = _resolveComponent("header-wizard")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_tm_content = _resolveComponent("tm-content")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (!_ctx.isXlMax)
      ? (_openBlock(), _createBlock(_component_main_sidebar, {
          key: 0,
          menu: _ctx.routes,
          opened: "",
          "is-links-hidden": "",
          "hide-minimize-button": ""
        }, null, 8, ["menu"]))
      : _createCommentVNode("", true),
    _createVNode(_component_tm_content, null, {
      default: _withCtx(() => [
        _createVNode(_component_header_wizard),
        _createVNode(_component_router_view, { "steps-size": _ctx.stepsSize }, null, 8, ["steps-size"])
      ]),
      _: 1
    })
  ]))
}