
import { defineComponent, computed } from 'vue'
import TmContent from '@/components/layout/TmContent.vue'
import HeaderWizard from '@/components/layout/header/HeaderWizard.vue'
import MainSidebar from '@/components/layout/mainSidebar/MainSidebar.vue'
import navigationService from '@/services/navigationService'
import { useBreakpoints } from '@/compositions/breakpoints'

export default defineComponent({
  components: {
    TmContent,
    HeaderWizard,
    MainSidebar,
  },
  setup() {
    const routes = navigationService.getBaseRoutes()

    const { isXlMax, isMdMax } = useBreakpoints()

    const stepsSize = computed(() => {
      if (!isMdMax.value) return '520px'
      return '420px'
    })

    return {
      routes,
      isXlMax,
      isMdMax,
      stepsSize,
    }
  },
})
