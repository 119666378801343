
import { defineComponent, computed } from 'vue'
import { useRoute } from 'vue-router'
import TmHeader from '@/components/layout/header/Header.vue'
import TmDropdown from '@/components/shared/TmDropdown.vue'
import TmDropdownItem from '@/components/shared/TmDropdownItem.vue'
import HeaderProfileButton from '@/components/layout/header/headerProfile/HeaderProfileButton.vue'

export default defineComponent({
  components: {
    TmHeader,
    HeaderProfileButton,
    TmDropdown,
    TmDropdownItem,
  },
  setup() {
    const route = useRoute()
    const price = computed(() => route.meta.price)

    return {
      price,
    }
  },
})
